import React, { useEffect, useState } from 'react';
import api from './axiosConfig'; // Ensure this path matches where you placed axiosConfig.js
import Login from './Login'; // Import the Login component

const App = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Authentication state

  useEffect(() => {
    // Check local storage for authentication status
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      api.get('/getUsers') // Ensure this matches your backend route
        .then(res => setUsers(res.data))
        .catch(err => setError(err.message)); // Handle errors
    }
  }, [isAuthenticated]);

  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true'); // Store authentication status
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    if (confirmDelete) {
      try {
        await api.delete(`/deleteUser/${id}`); // Ensure this matches your backend route
        setUsers(users.filter(user => user._id !== id)); // Update local state
      } catch (err) {
        setError(err.message); // Handle errors
      }
    }
  };

  return (
    <div className="flex flex-col">
      {!isAuthenticated ? (
        <Login onLogin={handleLogin} />
      ) : (
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              {error && <div className="text-red-500">{`Error: ${error}`}</div>} {/* Display error message */}
              <table className="min-w-full">
                <thead className="border-b">
                  <tr>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">S.No</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Full Name</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Phone Number</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Email</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Street Address</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">MLS ID</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Licence Number</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Primary Areas</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Radius Miles</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Secondary Areas</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    users.map((user, index) => (
                      <tr key={user._id} className="border-b">
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{index + 1}</td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{user.fullname}</td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{user.phonenumber}</td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{user.email}</td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{user.streetaddress}</td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{user.mlsid}</td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{user.licenceNumber}</td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{user.primaryAreas}</td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{user.radiusMiles}</td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{user.secondaryAreas}</td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          <button
                            onClick={() => handleDelete(user._id)}
                            className="text-red-600 hover:text-red-900"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
